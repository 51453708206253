import React, {useState} from 'react';
import './AdModifyer.styles.scss';
import PropTypes from 'prop-types';
import axios from 'axios';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import {sessionId} from '../../form/adCreator-form/AdCreatorForm';
import {STAGE} from '../../../socket.js';
const AdModifyer = ({
  setModifyingAd,
  campaignIndex,
  deliverableIndex,
  promptEntries,
  setPromptEntries,
  createdAds,
  waitingFor,
  setWaitingFor,
  startStage,
  invokeErrorMessage,
  wholeCampaign,
  setModifyingCampaign,
  setModifyAdResponses,
}) => {
  const [campaignPromptEntry, setCampaignPromptEntry] = useState(Array.from({length: createdAds.length}, () => ({prompt: ''})));

  const handleMakeModifyRequest = async (requestBody) => {
    try {
      // Replace with actual API request
      setWaitingFor('Making POST request to modify-ad');
      const token = localStorage.getItem('id_token');
      if (!token) {
        setWaitingFor('');
        return invokeErrorMessage('Authentication Error', 'User is not authenticated. Please log in again.');
      }
      const response = await axios.post(`https://fouqu424cr4g6g62lwoir2jswy0koyjx.lambda-url.us-east-1.on.aws/${STAGE}/mediaferry/mfa-modifyCampaign`, JSON.stringify(requestBody), {
        headers: {'Content-Type': 'application/json', Authorization: token},
      });
      console.log('response', response);
      if (response.status !== 200) {
        console.log('RESPONSE:::', response);
        setWaitingFor('');
      }
      const responseData = response.data;
      const modifyAdResponses = requestBody.expandedCampaign.deliverables.length;
      setModifyAdResponses(modifyAdResponses);
      setWaitingFor(`waiting for ${modifyAdResponses} responses from deliverableModified socket response.`);
      return startStage('Modify campaigns & deliverables', responseData);
    } catch (error) {
      console.log('ERROR OCCURRED WHILE MAKING A REQUEST TO THE API:', error);
      setWaitingFor('');
      return invokeErrorMessage(error.message, error);
    }
  };

  const handlePromptEntryChange = (e, campaignIndex, deliverableIndex) => {
    const {value} = e.target;
    !wholeCampaign
      ? setPromptEntries((prevEntries) => {
          // Create a new array to avoid mutating state directly
          const newEntries = [...prevEntries];
          // Create a new object for the specific entry to maintain immutability
          newEntries[campaignIndex] = [...newEntries[campaignIndex]];
          newEntries[campaignIndex][deliverableIndex] = {
            ...newEntries[campaignIndex][deliverableIndex],
            prompt: value,
          };
          return newEntries;
        })
      : setCampaignPromptEntry((_prevEntries) => {
          const newEntry = [..._prevEntries];
          newEntry[campaignIndex].prompt = value;
          return newEntry;
        });
  };

  const getAllDeliverables = (campaignIndex) => {
    const allDeliverables = createdAds[campaignIndex]
      .map((createdAd, index) => {
        if (createdAd !== null && createdAd.template.adSize) {
          const {url, obscureString} = createdAd;
          return {templateUrl: url, obscureString, campaignIndex, deliverableIndex: index};
        } else {
          return '';
        }
      })
      .filter((deliverables) => {
        return deliverables !== '';
      });
    return allDeliverables;
  };

  const handleSubmitDeliverableToModify = async (campaignIndex, deliverableIndex) => {
    const prompt = !wholeCampaign ? promptEntries[campaignIndex][deliverableIndex].prompt : campaignPromptEntry[campaignIndex].prompt;
    if (prompt.length === 0 || !prompt) {
      return invokeErrorMessage('Empty prompt!!', 'prompt input field cannot be empty');
    }

    const {url, obscureString} = createdAds[campaignIndex][deliverableIndex];
    const deliverables = !wholeCampaign ? [{templateUrl: url, obscureString: obscureString, campaignIndex, deliverableIndex}] : getAllDeliverables(campaignIndex);

    const requestBody = {source: 'mfa', sessionId, prompt, expandedCampaign: {deliverables}};
    await handleMakeModifyRequest(requestBody);
  };

  return (
    <>
      <div className="adModifyer-container">
        <FormLabel className="textArea-label">Modify Ad:</FormLabel>
        <Textarea
          className="modifyAd-textArea"
          value={!wholeCampaign ? promptEntries?.[campaignIndex]?.[deliverableIndex]?.prompt || '' : campaignPromptEntry?.[campaignIndex]?.prompt || ''}
          onChange={(e) => handlePromptEntryChange(e, campaignIndex, deliverableIndex)}
          placeholder="Enter Prompts.."
          minRows={2}
          maxRows={3}
          variant="outlined"
        />
        <div className="action-buttons">
          <button
            className={`download-zip-button ${waitingFor ? 'custom-button-disabled' : ''}`}
            disabled={!!waitingFor}
            onClick={() => {
              handleSubmitDeliverableToModify(campaignIndex, deliverableIndex);
            }}
          >
            {wholeCampaign ? 'Modify - Campaign' : 'Modify - deliverable'}
          </button>
          <button
            className={`cancel-modify-button ${waitingFor ? 'custom-button-disabled' : ''}`}
            disabled={!!waitingFor}
            onClick={() => {
              !wholeCampaign ? setModifyingAd(null) : setModifyingCampaign(null);
            }}
          >
            Cancel X
          </button>
        </div>
      </div>
    </>
  );
};

AdModifyer.propTypes = {
  setModifyingAd: PropTypes.func.isRequired,
  campaignIndex: PropTypes.number.isRequired,
  deliverableIndex: PropTypes.number.isRequired,
  promptEntries: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        prompt: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  setPromptEntries: PropTypes.func.isRequired,
  createdAds: PropTypes.array.isRequired,
  waitingFor: PropTypes.string.isRequired,
  setWaitingFor: PropTypes.func.isRequired,
  startStage: PropTypes.func.isRequired,
  invokeErrorMessage: PropTypes.func.isRequired,
  wholeCampaign: PropTypes.bool,
  setModifyingCampaign: PropTypes.func,
  setModifyAdResponses: PropTypes.func.isRequired,
};

export default AdModifyer;
