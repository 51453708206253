//React Imports
import React, {useEffect} from 'react';

import './AdCreatorForm.style.scss';
//components and utility functions imports

import {CustomButton} from '../Button/buttonComponents.component';
import {createQueryString, parseQueryString, extractPidFromUrl} from '../../../utils/urlUtils';
import {isArrayEmpty} from '../../../utils/helpers';
import {useFetchSubTeams} from '../../../hooks/useFetchSubTeams';
import mfaCallApi from '../../../utils/mfaCallApi';
import InputComponent from '../InputComponent/input.component';
import {styles, themes, channels, formatsDigitalOptions, fetchTemplatesFromOption} from './../../../utils/dropdownValues';
import {languages} from './../../../utils/languages';
//packages and library imports
import {v4 as uuidv4} from 'uuid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';
//accordion settings
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails, {accordionDetailsClasses} from '@mui/joy/AccordionDetails';
import AccordionSummary, {accordionSummaryClasses} from '@mui/joy/AccordionSummary';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import ListItemContent from '@mui/joy/ListItemContent';
import validator from 'validator';

const sessionId = uuidv4();

let projectId;
let numberOfCampaigns;

const AdCreatorForm = ({waitingFor, setWaitingFor, invokeErrorMessage, inhibitZipDownload, formData, setFormData, submitChooseTemplateCampaigns, tabValue, setTabValue, createdAds, teamOptions}) => {
  // console.log('form Data:::::::', formData);
  const {subTeams, loading, error, fetchSubTeams} = useFetchSubTeams();
  const handleTabsChange = (e, newValue) => {
    e.preventDefault();
    console.log('TABS CHANGEEE');
    setTabValue(newValue);
    const projectIdQueryString = createQueryString('projectId', formData.uuid);
    const tabQueryString = createQueryString('tab', newValue);
    const createdAdsQueryValue = !isArrayEmpty(createdAds) ? 'yes' : 'no';
    const createdAdsQueryString = createQueryString('createdAd', createdAdsQueryValue);
    window.history.pushState({}, '', `${window.location.pathname}?${projectIdQueryString}&${tabQueryString}&${createdAdsQueryString}`);
  };
  const handleCheckboxChange = (e) => {
    const {name, checked} = e.target;

    setFormData((prev) => {
      // Determine if numberOfAdaptations should be reset based on the checkbox state
      const resetNumberOfAdaptations = name === 'multipleAdaptations' && !checked;

      return {
        ...prev,
        [name]: checked,
        numberOfAdaptations: resetNumberOfAdaptations ? 0 : prev.numberOfAdaptations,
      };
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Update state with query parameters on mount
  useEffect(() => {
    // `parseQueryString` returns null if the query parameter is not found.
    const tabQueryParams = parseQueryString('tab') ?? '1';
    const projectId = parseQueryString('projectId') ?? undefined;
    const createdAdsQueryString = parseQueryString('createdAd') ?? 'no';
    let newFormData = {};
    let hasSubmitted = false;

    setFormData((prevState) => {
      newFormData = {...prevState, uuid: projectId};
      if (!newFormData.uuid) {
        newFormData.uuid = uuidv4();
      }

      if (!hasSubmitted && createdAdsQueryString === 'yes') {
        hasSubmitted = true;
        submitReadProject('no', newFormData);
      }

      return newFormData;
    });

    const tabQuery = Object.keys(tabQueryParams).length === 0 ? '1' : tabQueryParams;
    setTabValue(tabQuery);
  }, []);

  const submitReadProject = async (shouldExtractProjectId, formData) => {
    // Simplify projectId assignment
    const projectId = shouldExtractProjectId === 'yes' ? extractPidFromUrl(formData?.mfmProjectUrl) : 'none';

    // Directly construct bodyObject including conditional formData
    const bodyObject = {
      sessionId,
      _id: projectId,
      prKey: 'prkeys',
      source: 'form',
      ...(shouldExtractProjectId !== 'yes' && {formData}),
    };

    try {
      setWaitingFor('Read Project POST response'); // Set this before the API call to ensure it's set even if the call fails fast
      const response = await mfaCallApi('readProject', bodyObject);

      if (response.status !== 200) {
        return invokeErrorMessage('Error making post Request to MFA-READ PROJECT', 'you have encountered an HTTP error While making a POST request to MFA-READ PROJECT!');
      }
      setWaitingFor('Read Project socket response');

      console.log('mfa-choose-template-campaigns-response', response?.data?.data?.executionArn);
      return;
    } catch (err) {
      console.error('Error making post Request to MFA API', err); // Use console.error for better error visibility
      setWaitingFor('');
      return invokeErrorMessage('Error making post Request to MFA READ Project', err.message);
    }
  };

  const handleChange = (event) => {
    const {name, value, type} = event.target;
    // Update form data in state
    // console.log('NAME:::', name, 'VALUE:::', value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'number' ? parseInt(value) : value,
    }));
  };

  const addNewDocument = (e) => {
    e.preventDefault();

    setFormData((prevState) => ({
      ...prevState,
      documents: [...prevState.documents, {name: '', url: ''}],
    }));
  };

  const handleDocumentsChange = (event, index) => {
    const {name, value} = event.target;
    const updatedDocuments = formData?.documents?.map((item, idx) => {
      if (idx === index) {
        // Return a new object with the updated value for the specified name
        return {...item, [name]: value};
      }
      return item;
    });
    setFormData((prevState) => ({
      ...prevState,
      documents: updatedDocuments,
    }));
  };

  const deleteDocumentEntry = (e, index) => {
    e.preventDefault();
    const filteredEntry = formData?.documents?.filter((_pe, i) => {
      return i !== index;
    });
    setFormData((prevState) => ({
      ...prevState,
      documents: filteredEntry,
    }));
  };

  return (
    <>
      <div className="adcreator-container">
        {/* <div>
          <img src="https://demo.ekcs.io/demo-email/images/logo.png" alt="MediaFerry-Logo" />
        </div> */}
        <h3>MFA-TESTING 3</h3>
        <p className="version-p">(Pre-alpha Version 0.1.0)</p>
        <TabContext value={tabValue}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList centered textColor="secondary" indicatorColor="secondary" onChange={handleTabsChange} aria-label="lab API tabs example">
              <Tab wrapped label="Use mfm-Project Url" value="1" />
              <Tab wrapped label="Populate-Fields" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <form noValidate autoComplete="off">
              <InputComponent name="mfmProjectUrl" label="projectUrl" type="text" value={formData.mfmProjectUrl} onChange={handleChange} />

              <div style={{margin: '50px 0px'}} className="flex-style-column">
                <CustomButton
                  className={waitingFor || inhibitZipDownload ? 'custom-button-disabled' : ''}
                  onClick={() => {
                    submitReadProject('yes', formData);
                  }}
                  disabled={!!waitingFor || inhibitZipDownload}
                >
                  Read Project
                </CustomButton>
              </div>
            </form>
          </TabPanel>
          <TabPanel value="2">
            {' '}
            <form noValidate autoComplete="off">
              <div className="form-container">
                <div className="form-container-left">
                  <InputComponent type="text" name="projectName" label="Project Name (optional)" value={formData.projectName} onChange={handleChange} />
                  <InputComponent type="text" name="website" label="Website" value={formData.website} onChange={handleChange} />
                  <InputComponent type="text" name="targetMarket" label="Target-Market" value={formData.targetMarket} onChange={handleChange} />
                  <InputComponent type="text" name="targetAgeRange" label="Target Age-Range" value={formData.targetAgeRange} onChange={handleChange} />
                  <InputComponent type="number" name="textSearchDepth" label="Text Search Depth" value={formData.textSearchDepth} onChange={handleChange} />
                  <InputComponent type="number" name="imageSearchDepth" label="Image Search Depth" value={formData.imageSearchDepth} onChange={handleChange} />

                  <Autocomplete
                    value={formData.language}
                    name="language"
                    onChange={(_event, value) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        language: value || 'English',
                      }));
                    }}
                    id="controllable-states-demo"
                    options={languages}
                    sx={{width: 300}}
                    renderInput={(params) => <TextField {...params} label="Languages" />}
                  />
                  <AccordionGroup
                    variant="plain"
                    transition="0.2s"
                    sx={{
                      marginTop: '10px',
                      [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]: {
                        paddingBlock: '10px',
                      },
                      [`& .${accordionSummaryClasses.button}`]: {
                        paddingBlock: '10px',
                      },
                    }}
                  >
                    <Accordion
                      sx={{
                        border: '1px solid black',
                        float: 'right',
                      }}
                    >
                      <AccordionSummary>
                        <ListItemContent>
                          <Typography style={{marginLeft: '20px'}} level="title-md">
                            Choose Advertiser Team
                          </Typography>
                        </ListItemContent>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack spacing={2}>
                          <Autocomplete
                            id="teamName-autocomplete"
                            options={teamOptions}
                            getOptionLabel={(option) => option.team}
                            value={teamOptions.find((option) => option.team === formData.teamName) || null}
                            onChange={async (event, newValue) => {
                              console.log(`Team selected is ${newValue?.team}`);
                              console.log(`Team Id is ${newValue?.teamId}`);

                              if (newValue?.teamId) {
                                // Fetch subTeams and modifiers using the custom hook
                                console.log('Trying to fetch subTeams');
                                await fetchSubTeams(newValue.teamId);
                              } else {
                                // If no team is selected, clear subTeams and modifiers
                                setFormData((prevState) => ({
                                  ...prevState,
                                  teamName: '',
                                  subTeamName: '',
                                  modifierName: [],
                                }));
                              }

                              // Update formData with the selected teamName and reset subTeamName and modifierName
                              setFormData((prevState) => ({
                                ...prevState,
                                teamName: newValue ? newValue.team : '',
                                subTeamName: '',
                                modifierName: [],
                              }));
                            }}
                            renderInput={(params) => <TextField {...params} label="Team Name" variant="outlined" required />}
                          />

                          {/* SubTeams Dropdown */}
                          {subTeams.length > 0 && (
                            <Autocomplete
                              id="subTeamName-autocomplete"
                              options={subTeams}
                              getOptionLabel={(option) => option || ''}
                              value={formData.subTeamName || null}
                              onChange={(event, newValue) => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  subTeamName: newValue || '',
                                }));
                              }}
                              renderInput={(params) => <TextField {...params} label="Sub-Team Name" variant="outlined" />}
                            />
                          )}

                          {/* Loading and Error Messages */}
                          {loading.subTeams && <p>Loading sub-teams...</p>}
                          {error.subTeams && <p className="error-text">{error.subTeams}</p>}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionGroup>

                  <div className="checkbox-container-left">
                    <label style={{color: 'black'}}>Create multiple adaptations</label>
                    <input type="checkbox" name="multipleAdaptations" checked={formData.multipleAdaptations} onChange={handleCheckboxChange} />
                    {formData.multipleAdaptations && (
                      <>
                        <div style={{margin: '10px 0px', fontSize: '10px'}}>
                          <InputComponent type="number" name="numberOfAdaptations" label="number of adaptations" value={formData.numberOfAdaptations} onChange={handleChange} />
                        </div>
                      </>
                    )}
                  </div>
                  {/*  */}
                </div>
                {/* projectUrl */}
                <div className="form-container-right">
                  <InputComponent name="numberOfCampaigns" label="Number Of Campaigns" type="number" value={formData.numberOfCampaigns} onChange={handleChange} />
                  <InputComponent
                    type="text"
                    name="containingCampaignIds"
                    label="Campaign _ids (optional)"
                    value={formData.containingCampaignIds}
                    onChange={handleChange}
                    helpText="Specify template campaign _ids like 661fc7085087c72e43cd6bca to choose only those specific template campaigns.&#10;Leave blank for random campaign selection.&#10;Style and theme still have to match. "
                  />

                  <Box className="material-ui-select" sx={{minWidth: 120}}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label3">Style</InputLabel>
                      <Select
                        labelId="select-label3"
                        id="styleSelect"
                        name={'style'}
                        value={Array.isArray(formData.style) ? formData.style : [formData.style]}
                        label="Style"
                        onChange={handleChange}
                        multiple
                        renderValue={(selected) => selected?.join?.(', ')}
                      >
                        {styles.map((style) => (
                          <MenuItem key={style.value} value={style.value}>
                            {style.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {/*  */}
                  <Box className="material-ui-select" sx={{minWidth: 120}}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label4">Theme</InputLabel>
                      <Select
                        labelId="select-label4"
                        id="themeSelect"
                        name={'theme'}
                        value={Array.isArray(formData.theme) ? formData.theme : [formData.theme]}
                        label="Theme"
                        onChange={handleChange}
                        multiple
                        renderValue={(selected) => selected?.join?.(', ')}
                      >
                        {themes.map((theme) => (
                          <MenuItem key={theme.value} value={theme.value}>
                            {theme.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {/*  */}
                  <Box className="material-ui-select" sx={{minWidth: 120}}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label1">Channel</InputLabel>
                      <Select labelId="select-label1" id="channelSelect" multiple name={'channel'} value={formData.channel} label="Channel" onChange={handleChange}>
                        {channels.map((channel) => (
                          <MenuItem key={channel.value} value={channel.value}>
                            {channel.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {/*  */}
                  <Box className="material-ui-select" sx={{minWidth: 120}}>
                    <FormControl fullWidth>
                      <InputLabel id="select-label2">Format - Digital</InputLabel>
                      <Select
                        labelId="select-label2"
                        id="formatSelect"
                        // multiple={!formData.multipleAdaptations}
                        multiple
                        value={formData.formatsDigital}
                        name="formatsDigital"
                        onChange={handleChange}
                        input={<OutlinedInput label="format" />}
                        label="Format - Digital"
                        MenuProps={MenuProps}
                      >
                        {formatsDigitalOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <AccordionGroup
                    variant="plain"
                    transition="0.2s"
                    sx={{
                      marginTop: '10px',
                      [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]: {
                        paddingBlock: '10px',
                      },
                      [`& .${accordionSummaryClasses.button}`]: {
                        paddingBlock: '10px',
                      },
                    }}
                  >
                    <Accordion
                      sx={{
                        border: '1px solid black',
                        float: 'right',
                      }}
                    >
                      <AccordionSummary>
                        <ListItemContent>
                          <Typography style={{marginLeft: '20px'}} level="title-md">
                            Choose Template
                          </Typography>
                        </ListItemContent>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack spacing={0}>
                          <InputComponent type="text" name="templateTeamName" label="Team name" value={formData.templateTeamName} onChange={handleChange} />
                          <FormControl sx={{width: 305}}>
                            <InputLabel id="select-label2">Get Templates From</InputLabel>
                            <Select
                              labelId="select-label2"
                              id="getTemplateSelect"
                              value={formData.fetchTemplatesFrom}
                              name="fetchTemplatesFrom"
                              style={{width: '80%'}}
                              onChange={handleChange}
                              input={<OutlinedInput label="format" />}
                              label="Get Templates From"
                              MenuProps={MenuProps}
                            >
                              {fetchTemplatesFromOption.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionGroup>
                  {/*  */}
                  <div className="checkbox-container-right">
                    <label style={{color: 'black'}}>Apply brand-colors</label>
                    <input type="checkbox" name="applyBrandColors" checked={formData.applyBrandColors} onChange={handleCheckboxChange} />
                  </div>
                  <button className="add-document-button" onClick={addNewDocument}>
                    Add new Document <b>+</b>
                  </button>
                </div>

                {/* New field for 'Theme' */}
              </div>
              {formData?.documents?.map((doc, index) => {
                return (
                  <div key={index} className="documents-container">
                    <InputComponent
                      style={{maxWidth: '95%'}}
                      value={doc.name}
                      name={'name'}
                      label={'name'}
                      className={doc?.name.length <= 2 ? 'color-red' : ''}
                      onChange={(e) => {
                        handleDocumentsChange(e, index);
                      }}
                      validationError={doc?.name?.length > 0 && doc?.name.length <= 2}
                      errorMessage={'A name must be more than 5 characters long'}
                    />

                    <InputComponent
                      style={{maxWidth: '95%'}}
                      value={doc.url}
                      name={'url'}
                      label={'url'}
                      className={!validator.isURL(doc?.url) ? 'color-red' : ''}
                      onChange={(e) => {
                        handleDocumentsChange(e, index);
                      }}
                      validationError={doc?.url?.length > 0 && !validator.isURL(doc?.url)}
                      errorMessage={'please enter a valid url'}
                    />

                    <button
                      onClick={(e) => {
                        deleteDocumentEntry(e, index);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                );
              })}

              <FormLabel className="textArea-label">Special Instructions(optional)</FormLabel>
              <Textarea
                className="specialInstruction-textArea"
                // style={{width: 'inherit', color: 'black', border: '1px solid black'}}
                name="specialInstructions"
                value={formData.specialInstructions}
                onChange={handleChange}
                placeholder="special instructions...."
                minRows={4}
                variant="outlined"
              />

              <div className="button-container">
                <CustomButton className={waitingFor ? 'custom-button-disabled' : ''} onClick={submitChooseTemplateCampaigns} disabled={!!waitingFor} id="chooseTemplateButton">
                  Choose Template Campaigns
                </CustomButton>
              </div>
            </form>
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};

AdCreatorForm.propTypes = {
  waitingFor: PropTypes.string,
  inhibitZipDownload: PropTypes.bool,
  setWaitingFor: PropTypes.func,
  tabValue: PropTypes.string,
  setTabValue: PropTypes.func,
  invokeErrorMessage: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  submitChooseTemplateCampaigns: PropTypes.func,
  teamOptions: PropTypes.array,

  createdAds: PropTypes.array,
};

export {AdCreatorForm, sessionId, projectId, numberOfCampaigns};
