import React from 'react';
import PropTypes from 'prop-types';

const executionPrefix = 'https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/v2/executions/details/';

const StageInfo = ({stage}) => {
  const {stageName, startTime, endTime, executionArn, detail} = stage;

  const containerStyle = {
    fontFamily: 'Dejavu Sans Mono, monospace',
    fontSize: '10px',
    marginTop: '0',
    marginBottom: '0',
    textAlign: 'left',
  };

  return (
    <div style={containerStyle}>
      {executionArn ? (
        <a href={`${executionPrefix}${executionArn}`} target="_blank" rel="noopener noreferrer" style={{display: 'block'}}>
          <div>
            {stageName}
            {detail ? ` ${detail}` : ''}
          </div>
        </a>
      ) : (
        <div>
          {stageName}
          {detail ? ` ${detail}` : ''}
        </div>
      )}
      {startTime && <div>Start: {startTime}</div>}
      {endTime && <div>&nbsp;&nbsp;End: {endTime}</div>}
    </div>
  );
};

StageInfo.propTypes = {
  stage: PropTypes.shape({
    stageName: PropTypes.string.isRequired,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    executionArn: PropTypes.string,
    detail: PropTypes.string,
  }).isRequired,
};

export {StageInfo};
