export const handleDownloadClick = async (request, setInhibitZipDownload) => {
  console.log('Request:', request);

  try {
    const response = await fetch('https://zxjcrsk5gxcpqhm5dueulszys40bwsad.lambda-url.us-east-1.on.aws/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the filename from the response headers
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'download.zip';

    if (contentDisposition && contentDisposition.includes('filename=')) {
      const filenameMatch = contentDisposition.match(/filename="(.+)"/);
      if (filenameMatch.length > 1) {
        filename = filenameMatch[1];
      }
    } else {
      filename = request.zipFileName ?? request.combinedZipFileName ?? 'download.zip';
    }

    // Read the response as a blob
    const blob = await response.blob();

    // Create a link, trigger download, and remove the link
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL after the download
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Download failed:', error);
  } finally {
    setInhibitZipDownload(false);
  }
};
