// Connect to mfa-websocket-gateway
// and handle messages coming from the gateway
// Written by chatgpt4
// Just use "import ./socket.js" to execute this exactly once.

// We invent a unique sessionId for this instance of the webpage.
// We send that to open the socket and the code there stores the connectionId
// in a database, keyed by sessionId.
// We tell the Step Function the sessionId. It tells its lambdas.
// The lambdas quote the sessionId in their messages to the gateway.
// The gateway uses the sessionId to look up the connectionId.
// The gateway sends the message to the connectionId.
// If the connection drops and is remade, the gateway will get a new connectionId
// and will update the database with the new connectionId.
// The lambdas will then send messages to the new connectionId.
// The sessionId never changes.

import {sessionId} from './Components/form/adCreator-form/AdCreatorForm.jsx';

const STAGE = 'develop'; // 'develop' or 'production'
// `stage` refers to the stage of the API gateway websocket.

const wsUrl = `wss://e2cx990fl5.execute-api.us-east-1.amazonaws.com/${STAGE}/?sessionId=${sessionId}`;

let ws;
let receiverFunction;
let messageNumber = 0;

const reportMessagesTo = ({receiver}) => {
  // This is called from outside to specify a function to which arriving messages should be sent.
  receiverFunction = receiver;
  console.log(`Have set receiverFunction. receiver:${typeof receiver}`);
};
const connectWebSocket = () => {
  ws = new WebSocket(wsUrl);

  ws.onopen = (event) => {
    console.log(`Connected to WebSocket. event:${JSON.stringify(event)} sessionID:${sessionId}`);
  };

  ws.onmessage = (event) => {
    messageNumber += 1; // Should always execute successfully.
    try {
      // console.log(`onMessage:${messageNumber}:non-parsed event.data: ${typeof event.data}:`, event.data);
      const messageData = JSON.parse(event.data);
      console.log(`onMessage:${messageNumber}:parsed event.data: ${typeof messageData}:`, messageData);
      if (receiverFunction) {
        // then there is somewhere to send the message
        receiverFunction({message: messageData});
      }
    } catch (error) {
      console.error(`onMessage:${messageNumber}:Error parsing JSON: ${error}\nMessage string: ${event.data}`);
    }
  };

  ws.onerror = (error) => {
    console.log(`WebSocket error: ${JSON.stringify(error)}`);
  };

  ws.onclose = (event) => {
    if (event.wasClean) {
      console.log(`Closed cleanly, code=${event.code}, reason=${event.reason}`);
      connectWebSocket();
    } else {
      // Connection was not closed cleanly, maybe server process crashed or network issues.
      // We can try to reconnect here.
      console.log('WebSocket connection closed unexpectedly. Attempting to reconnect...');
      setTimeout(connectWebSocket, 5000); // Try to reconnect every 5 seconds.
    }
  };
};

connectWebSocket();

export {reportMessagesTo, STAGE};
