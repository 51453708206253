import {useState, useCallback} from 'react';
import axios from 'axios';

const useFetchSubTeams = () => {
  const [subTeams, setSubTeams] = useState([]);
  const [loading, setLoading] = useState({subTeams: false});
  const [error, setError] = useState({subTeams: null});

  const fetchSubTeams = useCallback(async (teamId) => {
    if (!teamId) {
      setSubTeams([]);
      return;
    }

    setLoading((prev) => ({...prev, subTeams: true}));
    setError((prev) => ({...prev, subTeams: null}));

    try {
      const response = await axios.get('https://7jasqog6oggdr2cvel4cln2ele0ogvgf.lambda-url.us-east-1.on.aws/', {
        params: {
          teamId: teamId,
          subFolder: 'subTeams',
        },
      });

      if (response.status === 200) {
        const fetchedSubTeams = response.data.folders || [];
        console.log(`response is`);
        console.log(response);
        console.log(`data is: `);
        console.log(response.data);
        console.log(fetchedSubTeams);
        setSubTeams(fetchedSubTeams);
      } else if (response.status === 204) {
        setSubTeams([]);
      } else {
        console.error('Failed to fetch sub-team data:', response.status, response.statusText);
        setSubTeams([]);
        setError((prev) => ({...prev, subTeams: `Failed to fetch sub-teams: ${response.status} ${response.statusText}`}));
      }
    } catch (err) {
      console.error('Error fetching sub-team data:', err);
      setSubTeams([]);
      setError((prev) => ({...prev, subTeams: 'An error occurred while fetching sub-teams.'}));
    } finally {
      setLoading((prev) => ({...prev, subTeams: false}));
    }
  }, []);

  return {subTeams, loading, error, fetchSubTeams};
};

export {useFetchSubTeams};
